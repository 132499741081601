<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="naviageToManageUserAccount"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">New User Account</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <form @submit.prevent="addUser" class="card pt-2 form-control">
          <label class="label"
            ><span class="label-text text-black"
              >Email <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="email"
            type="email"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black"
              >Password <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="password"
            type="password"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black"
              >Retype Password <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="password_confirmation"
            type="password"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black">Account Remark</span></label
          >
          <input
            v-model="remark"
            type="text"
            class="input input-md input-bordered w-full"
          />

          <br />
          <button class="btn bg-green-500 border-none mt-3">Submit</button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";

import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "AddUserAccount",
  components: { SideNavBar, ChevronLeftIcon },
  data() {
    return {
      isLoading: false,
      email: "",
      password: "",
      password_confirmation: "",
      remark: "",
    };
  },
  methods: {
    addUser() {
      this.isLoading = true;
      this.axios({
        url: "/user/addUser",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          remark: this.remark,
        },
      })
        .then((response) => {
          this.isLoading = false;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: response.data["message"],
          });
          this.$router.push({ name: "ManageUserAccount" });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "error",
            title: e.response.data.message,
          });

          if (e.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    naviageToManageUserAccount() {
      this.$router.push({ name: "ManageUserAccount" });
    },
  },
};
</script>

<style scoped>
</style>